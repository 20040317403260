import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import {
  Banner, BannerContent, BannerDescription, BannerContentItem, BannerTitle, BannerButton,
} from '../../shared/Banner';
import { PageImage } from '../../shared/PageImage';
import HomeBannerIconSource from './images/homeBannerIcon.png';
import { SCREEN_SIZE, STYLES } from '../../../style';
import { MODAL_TYPE } from '../../shared/ModalManager/constants';
import { showModal } from '../../../states/modal/actions';

const StyledHomeBanner = styled(Banner)``;

const BannerImage = styled(PageImage)`
  cursor: pointer;
  ${SCREEN_SIZE.LG} {
    transform: translateY(0.45rem);
  }
`;

const HomeBanner = ({
  dispatch,
}) => (
  <StyledHomeBanner color={STYLES.PAGE_HOME_BANNER_COLOR}>
    <BannerContent>
      <BannerContentItem>
        <BannerImage
          src={HomeBannerIconSource}
          onClick={() => dispatch(showModal(MODAL_TYPE.YOUTUBE_MODAL))}
        />
      </BannerContentItem>
      <BannerContentItem flexSize={2}>
        <BannerTitle>Welcome to Hand Carry</BannerTitle>
        <BannerDescription>
          Hand Carry matches inner city parcel deliveries with people
          nearby who want to earn extra money by delivering these parcels
          who are already intending to go in a similar direction.
        </BannerDescription>
        <BannerButton to="/how-it-works">
          Learn more
        </BannerButton>
      </BannerContentItem>
    </BannerContent>
  </StyledHomeBanner>
);

HomeBanner.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const EnhancedHomeBanner = compose(
  connect(),
)(HomeBanner);

export { EnhancedHomeBanner as HomeBanner };