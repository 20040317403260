import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { SubSectionDescription, SubSectionTitle } from '../../../shared/Section'
import { Link } from '../../../shared/Link';

const StyledWhyHandCarryItem = styled.div`
  padding: 2rem 0;
`;

const WhyHandCarryItem = ({
  title,
  description,
  icon,
  link,
  linkText,
}) => (
  <StyledWhyHandCarryItem>
    <SubSectionTitle icon={icon}>{ title }</SubSectionTitle>
    <SubSectionDescription>{ description }</SubSectionDescription>
    <Link to={link}>{linkText}</Link>
  </StyledWhyHandCarryItem>
);

WhyHandCarryItem.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  icon: PropTypes.func.isRequired,
  link: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
};

export { WhyHandCarryItem };