import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Section, SubSection } from '../../../shared/Section';
import { SCREEN_SIZE } from '../../../../style';
import AppStoreLogoSource from './images/app-stores-logos.png';
import { Centralized } from '../../../shared/layout';

const HandCarryIphoneAppImage = styled.img`
  margin-bottom: 3rem;
  max-width: 222px;
  ${SCREEN_SIZE.LG} {
    margin-bottom: 0;
    position: absolute;
    transform: translateY(-7rem);
  }
`;

const HandCarryAppLogo = styled.img`
  margin: 2rem auto;
  display: block;
  width: 160px;
`;

const DownloadHandCarryAppContent = styled(Centralized)`
  ${SCREEN_SIZE.LG} {
    display: flex;
    align-items: center;
  }
`;

const AppStoreLogo = styled.img.attrs({
  src: AppStoreLogoSource,
})`
  width: 250px;
`;

const ContentSubSection = styled(SubSection)`
  text-align: center;
  ${SCREEN_SIZE.LG} {
    padding: 5rem 0 3rem;
  }
`;

const StyledDownloadHandCarryApp = styled(Section)`
  background-color: ${({ bgColor }) => bgColor};
  position: relative;
  overflow: hidden;
  ${SCREEN_SIZE.LG} {
    padding-top: 0;
  }
`;

const DownloadHandCarryApp = ({
  bgColor,
  iphoneImage,
  appImage,
}) => (
  <StyledDownloadHandCarryApp bgColor={bgColor}>
    <DownloadHandCarryAppContent>
      <ContentSubSection>
        <HandCarryIphoneAppImage src={iphoneImage} />
      </ContentSubSection>
      <ContentSubSection>
        <HandCarryAppLogo src={appImage} />
        <AppStoreLogo />
      </ContentSubSection>
    </DownloadHandCarryAppContent>
  </StyledDownloadHandCarryApp>
);

DownloadHandCarryApp.propTypes = {
  bgColor: PropTypes.string.isRequired,
  iphoneImage: PropTypes.string.isRequired,
  appImage: PropTypes.string.isRequired,
};

export { DownloadHandCarryApp };