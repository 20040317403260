import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { SCREEN_SIZE, STYLES } from '../../../../style';
import TickImage from './images/tick.svg';

const StyledMerchantBenefitListItem = styled.li`
  ${SCREEN_SIZE.LG} {
    flex-basis: 50%;
  }
  display: flex;
  line-height: ${STYLES.LINE_HEIGHT_MD};
  padding: .75rem 0;
`;

const Content = styled.div`
  margin-right: 1rem;
`;

const StyledTickIcon = styled.img.attrs({
  src: TickImage,
})`
  color: ${STYLES.COLOR_APPLE_GREEN};
  margin-top: .35rem;
  width: 1.5rem;
  height: 1.5rem;
  margin-right: .75rem;
`;

const MerchantBenefitListItem = ({
  children,
}) => (
  <StyledMerchantBenefitListItem>
    <div><StyledTickIcon /></div>
    <Content>{children}</Content>
  </StyledMerchantBenefitListItem>
);

MerchantBenefitListItem.propTypes = {
  children: PropTypes.node.isRequired,
};

export { MerchantBenefitListItem };