import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ExclamationIcon from 'react-icons/lib/fa/exclamation';
import PencilIcon from 'react-icons/lib/ti/pencil';
import QuestionIcon from 'react-icons/lib/fa/question';
import {
  Section,
  SubSection,
  SubSectionDescription,
  SubSectionHeader,
  SubSectionTitle,
} from '../../../shared/Section';
import { Centralized } from '../../../shared/layout';
import StartEarningTodayImageSource from './start-earning-today.svg';
import { Link } from '../../../shared/Link';
import { SCREEN_SIZE, STYLES } from '../../../../style';
import { PageImage } from '../../../shared/PageImage';

const StyledStartEarningToday = styled(Section)``;

const StartEarningTodayContent = styled(Centralized)`
  ${SCREEN_SIZE.LG} {
    display: flex;
    align-items: center;
  }
`;

const StartEarningTodayImage = styled(PageImage).attrs({
  src: StartEarningTodayImageSource,
})``;

const StartEarningTodayLinkContainer = styled.ul`
  list-style: none;
  padding: 0;
  margin: 1.75rem 0 0 0;
`;

const StyledStartEarningTodayLinkItem = styled.li`
  margin-bottom: 1rem;
  align-items: center;
`;

const StartEarningTodayLinkItem = ({ children, icon, url }) => {
  const StyledIcon = styled(icon)`
    border-radius: 2rem;
    color: ${STYLES.COLOR_WHITE};
    background-color: ${STYLES.COLOR_BLACK};
    padding: .2rem;
    margin-right: .75rem;
  `;
  return (
    <StyledStartEarningTodayLinkItem>
      <StyledIcon />
      <Link to={url}>{ children }</Link>
    </StyledStartEarningTodayLinkItem>
  );
};

StartEarningTodayLinkItem.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
};

const StartEarningToday = () => (
  <StyledStartEarningToday>
    <StartEarningTodayContent>
      <SubSection>
        <StartEarningTodayImage />
      </SubSection>
      <SubSection>
        <SubSectionHeader>
          Start earning today
        </SubSectionHeader>
        <SubSectionTitle>
          Hand Carry matches small parcels with the movements of every day commuters.
        </SubSectionTitle>
        <SubSectionDescription>
          After setting your movements, we’ll let you know when a parcel matches
          where you’re going to, and from. Pick up a parcel, drop it off, get paid.
          Anyone can do it!
        </SubSectionDescription>
        <StartEarningTodayLinkContainer>
          <StartEarningTodayLinkItem
            icon={ExclamationIcon}
            url="/services"
          >
            Learn more about Hand Carry
          </StartEarningTodayLinkItem>
          <StartEarningTodayLinkItem
            icon={PencilIcon}
            url="/citizens"
          >
            Sign up and start earning
          </StartEarningTodayLinkItem>
          <StartEarningTodayLinkItem
            icon={QuestionIcon}
            url="/faq"
          >
            See our Frequently Asked Questions
          </StartEarningTodayLinkItem>
        </StartEarningTodayLinkContainer>
      </SubSection>
    </StartEarningTodayContent>
  </StyledStartEarningToday>
);

export { StartEarningToday };