import styled from 'styled-components';
import { SCREEN_SIZE } from '../../../../style';

const MerchantBenefitList = styled.ul`
  list-style: none;
  margin: 1.25rem 0 2.5rem;
  padding: 0;
  ${SCREEN_SIZE.LG} {
    display: flex;
    flex-flow: wrap;
  }
`;

export { MerchantBenefitList };