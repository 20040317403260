import React from 'react';
import styled from 'styled-components';
import {
  Section, SubSection, SubSectionDescription, SubSectionHeader, SubSectionTitle,
} from '../../../shared/Section';
import StoreIcon from './images/store.svg';
import AreYourMerchantImageSource from './are-you-merchant.png';
import { PageImage } from '../../../shared/PageImage';
import { Centralized } from '../../../shared/layout';
import { SCREEN_SIZE } from '../../../../style';
import { MerchantBenefitList } from './MerchantBenefitList';
import { MerchantBenefitListItem } from './MerchantBenefitListItem';
import { Link } from '../../../shared/Link';

const StyledAreYouMerchant = styled(Section)`
`;

const AreYouMerchantContent = styled(Centralized)`
  ${SCREEN_SIZE.LG} {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
  }
`;

const StyledStoreIcon = styled.img.attrs({
  src: StoreIcon,
})`
  margin-right: .75rem;
`;

const LinkIconContainer = styled.div`
  display: flex;
  align-items: center;
`;

const AreYourMerchantImage = styled(PageImage).attrs({
  src: AreYourMerchantImageSource,
})``;


const AreYouMerchant = () => (
  <StyledAreYouMerchant>
    <AreYouMerchantContent>
      <SubSection>
        <AreYourMerchantImage />
      </SubSection>
      <SubSection>
        <SubSectionHeader>
          Are you an eCommerce business?
        </SubSectionHeader>
        <SubSectionTitle>
          Offer your customers lightning fast shipping that’s
          cheaper and better for the environment.
        </SubSectionTitle>
        <SubSectionDescription>
          Hand Carry utilises the movements of every day citizens to
          create an agile delivery network. Take advantage of the speed
          and efficiency that we can offer.
        </SubSectionDescription>
        <MerchantBenefitList>
          <MerchantBenefitListItem>
            Thousands of commuters ready to deliver your parcels
          </MerchantBenefitListItem>
          <MerchantBenefitListItem>
            Utilising existing infra-structure we become carbon neutral
          </MerchantBenefitListItem>
          <MerchantBenefitListItem>
            Couriers and parcels are insured
          </MerchantBenefitListItem>
          <MerchantBenefitListItem>
            30% cheaper than all options available
          </MerchantBenefitListItem>
          <MerchantBenefitListItem>
            No integration required to existing work flows
          </MerchantBenefitListItem>
          <MerchantBenefitListItem>
            Loyalty bonuses for early adopters
          </MerchantBenefitListItem>
        </MerchantBenefitList>
        <LinkIconContainer>
          <StyledStoreIcon /><Link to="/businesses">Learn about how Hand Carry can work for you</Link>
        </LinkIconContainer>
      </SubSection>
    </AreYouMerchantContent>
  </StyledAreYouMerchant>
);

export { AreYouMerchant };