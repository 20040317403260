import React from 'react';
import styled from 'styled-components';

import { Layout } from '../components/shared/layout';
import { Page } from '../components/shared';
import { DownloadCommutersApp } from '../components/modules/common/DownloadHandCarryApp';
import {
  AreYouMerchant,
  HomeBanner,
  StartEarningToday,
  WhyHandCarry,
} from '../components/modules/HomePage';

const StyledHomePage = styled(Layout)`
  text-align: center;
`;

const HomePage = () => (
  <StyledHomePage headerTransparent>
    <Page>
      <HomeBanner />
      <StartEarningToday />
      <WhyHandCarry />
      <AreYouMerchant />
      <DownloadCommutersApp />
    </Page>
  </StyledHomePage>
);

export default HomePage;
