import React from 'react';
import styled from 'styled-components';
import iPhoneIcon from 'react-icons/lib/md/stay-current-portrait';
import EarthIcon from 'react-icons/lib/io/earth';
import GroupIcon from 'react-icons/lib/fa/group';
import FlashIcon from 'react-icons/lib/io/flash';
import { WhyHandCarryItem } from './WhyHandCarryItem';
import { SCREEN_SIZE, STYLES } from '../../../../style';
import { Section, SubSection } from '../../../shared/Section';
import { Centralized } from '../../../shared/layout';

const StyledWhyHandCarry = styled(Section)`
  background-color: ${STYLES.SECTION_GRAY_BACKGROUND_COLOR};
  padding-top: 1rem;
  padding-bottom: 1rem;
`;

const WhyHandCarryContent = styled(Centralized)`
  ${SCREEN_SIZE.LG} {
    display: flex;
    flex-flow: wrap;
  }
`;

const WhyHandCarry = () => (
  <StyledWhyHandCarry>
    <WhyHandCarryContent>
      <SubSection>
        <WhyHandCarryItem
          title="Faster than any courier"
          description="Commuters are commuting around the city non stop. That means we have someone ready to deliver your parcel nearby, right now."
          icon={FlashIcon}
          link="/businesses/how-to"
          linkText="See how fast our average delivery times are"
        />
        <WhyHandCarryItem
          title="Easier and more efficient"
          description="No more waiting in line or for couriers to pick up your parcels. Simply post your job with our app and we’ll take care of the rest."
          icon={iPhoneIcon}
          link="/businesses"
          linkText="Learn about how we integrate with businesses"
        />
      </SubSection>
      <SubSection>
        <WhyHandCarryItem
          title="Better for the environment"
          description="With no unnecessary trips, it means no trucks and no depots. Your parcels are going direct, utilising otherwise wasted energy."
          icon={EarthIcon}
          link="/businesses/savings"
          linkText="Visualise how much energy and resources we’ve saved"
        />
        <WhyHandCarryItem
          title="Giving back to the community"
          description="70% of our profits go back to the community for delivering the parcels. Help us strengthen the economy and be part of something great."
          icon={GroupIcon}
          link="/citizens"
          linkText="Hear from real people who Hand Carry"
        />
      </SubSection>
    </WhyHandCarryContent>
  </StyledWhyHandCarry>
);

export { WhyHandCarry };
